/*//*/
*{box-sizing:border-box}
html,body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,button,input,textarea,p,select,blockquote,th,td,img,a{margin:0;padding:0;font-weight:400;color:var(--mainTextColor);font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;word-break: keep-all;word-wrap: break-word;font-size: 1em;line-height: 140%;letter-spacing: -0.02em}
html,body{font-size:16px;width:100%;height: 100%;overscroll-behavior: contain;background-color: var(--bg)}
body.active{overflow-y: hidden}
body.loadingActive{overflow-y: hidden}
li{list-style:none}
a{text-decoration:none;color:var(--mainTextColor)}
a:hover{text-decoration:none}
a:active{text-decoration:none}
table{width:100%;border-collapse:collapse;border-spacing:0}
img{display:block;border:0}
button{background:transparent;border:0;cursor:pointer;outline:0}
input,select,textarea{border:1px solid transparent;-webkit-appearance: none;border-radius: 10px;background: var(--formBg);color: var(--mainTextColor);}
input[type="number"]::-webkit-outer-spin-button,input[type="number"]::-webkit-inner-spin-button{-webkit-appearance: none;margin: 0}
input[type="password"]{font-family: sans-serif}
input[type="password"]::placeholder{font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif}
input::placeholder{color:var(--placeholder)}
textarea::placeholder{color:var(--placeholder)}
input:focus,textarea:focus{outline: none;border:1px solid var(--focusLine) !important}
select{-moz-appearance:none;-webkit-appearance:none;appearance:none}
select::-ms-expand{display:none}
textarea{-ms-overflow-style:none;scrollbar-width:none}
textarea::-webkit-scrollbar{display:none}
input[type="radio"],input[type="checkbox"]{display: none}
input:disabled,input:read-only{cursor: auto;opacity: 1;color:var(--disabledColor);background: var(--disabledBg);border-color:var(--disabledLine)}
textarea:disabled,textarea:read-only{cursor: auto;opacity: 1;color:var(--disabledColor);background: var(--disabledBg);border-color:var(--disabledLine)}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{-webkit-text-fill-color: var(--mainTextColor);-webkit-box-shadow: 0 0 0 1000px var(--formBg) inset;box-shadow: 0 0 0 1000px var(--formBg) inset}
input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active{-webkit-text-fill-color: var(--mainTextColor);-webkit-box-shadow: 0 0 0 1000px var(--formBg) inset;box-shadow: 0 0 0 1000px var(--formBg) inset}

#root{width: 100%;overflow-x: hidden}
.pageSizing{min-height: calc(var(--vh, 1vh) * 100)}

/*scroll*/
@media (hover: none) and (pointer: coarse) {
	*{-ms-overflow-style: none;scrollbar-width: none}
	*::-webkit-scrollbar{display: none}
}
  
@media (hover: hover) and (pointer: fine) {
	*:not(body){-ms-overflow-style: none;scrollbar-width: none}
	*:not(body)::-webkit-scrollbar{display: none}

	body::-webkit-scrollbar,
	body::-webkit-scrollbar-thumb {height: 10px;max-width:10px;border-radius: 10px;background-clip: padding-box;border: 2px solid transparent}
	body::-webkit-scrollbar-thumb {box-shadow: inset 0 0 0 4px #939393}
	body::-webkit-scrollbar-track {background-color: #F9F8FA}
}

/*basic*/
.wra{width:100%;overflow: hidden}