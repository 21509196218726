@keyframes err{
  0%,
  100%{
    transform: translateX(0);
 }

  12.5%{
    transform: translateX(12px);
 }

  25%{
    transform: translateX(-12px);
 }

  37.5%{
    transform: translateX(8px);
 }

  50%{
    transform: translateX(-8px);
 }

  62.5%{
    transform: translateX(5px);
 }

  75%{
    transform: translateX(-5px);
 }

  87.5%{
    transform: translateX(3px);
 }
}

@keyframes spin{
  0%{
    transform: rotate(0);
 }
  100%{
    transform: rotate(360deg);
 }
}

@keyframes spinBanner{
  0%{
    transform: translateX(-50%) rotate(0);
 }
  100%{
    transform: translateX(-50%) rotate(360deg);
 }
}

@keyframes inputIn{
  0%{
    display: block;
  }
  100%{
    opacity: 1;display: block;font-size: 11px;top: 4px;transform: translate(0,2px);
  }
}

@keyframes inputOut{
  0%{
    opacity: 1;display: block;font-size: 11px;top: 4px;transform: translate(0,2px);
  }
  99%{
    display: block;
  }
  100%{
    font-size: 12px;top: 50%;transform: translate(-3px,calc(-50% - 1px));opacity: 0;display: none;
  }
}

@keyframes upTitleBox{
  from {
    z-index: 3;
  }

  to {
    z-index: 1;
  }
}

@keyframes filpUp{
  0% {
    transform: rotateX(0deg);
  }

  25% {
    transform: rotateX(-90deg);
  }

  100% {
    transform: rotateX(-90deg);
  }
}

@keyframes filpDown{
  0% {
    transform: rotateX(90deg);
  }

  25% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.gTextColor{color:var(--mainColor) !important}
.boldText{font-weight: 700 !important}
.backgroundImg{background-repeat: no-repeat;background-position: center;background-size: cover}

.rTextColor{color:var(--rTextColor) !important}
.rBg{background-color:var(--rBgColor) !important}

.mItem{display: none}

/*header*/
.header{position: fixed;top: 0;left: 0;width: 100%;z-index: 1000}
.headerSection{max-width: 1760px;margin: 0 auto;display: flex;align-items: center;justify-content: space-between;gap: 30px;height: 4.2em;padding: 0 20px;position: relative}
.headerSection.notBg{background: transparent}
.btnHeaderRight{position: absolute;right: 20px;top: 50%;transform: translateY(-50%)}
.btnHeaderRightItem{display: block;color: var(--mainTextColor);text-decoration: underline;font-size: 13px;font-weight: 600}
.btnHeaderLeft{position: absolute;left: 20px;top: 50%;transform: translateY(-50%)}
.headearSubSection{background: rgba(0, 0, 0, 0.07);padding: 0 20px}
.headers_subMenuBox{max-width: 1760px;margin: 0 auto;display: flex;align-items: center;justify-content: flex-end;height: 2.19em}
.headerBtn.headerSubBtn{font-size: 0.875em;position: relative}
.headerBtn.headerSubBtn + .headerBtn.headerSubBtn{ margin: 0 0 0 2.14em}
.headerBtn.headerSubBtn + .headerBtn.headerSubBtn::before{content: "";position: absolute;top: 50%;left: -0.94em;height: 15px;width: 1px;transform: translateY(-50%);background: #fff}
.btnHome{width: 10.63em;display: block}
.btnHome>img{width: 100%}
.headerBtn{font-size: 1.125em;font-weight: 600;text-align: left;color: #fff;opacity: 0.4}
.headerBtn.active{opacity: 1;font-weight: 700}
.headerBtn + .headerBtn{margin: 0 0 0 1.67em}
.headerBtn.lineBtn{font-size: 1em;font-weight: 700;opacity: 1;border: 1px solid #fff;border-radius: 7px;padding: 0.63em 1.25em;margin: 0 0 0 1.88em}

.header.active{background: #fff}
.header.active .headearSubSection{background: rgba(231, 236, 242, 0.3)}
.header.active .headerBtn{color: #124EA8}
.header.active .headerBtn.headerSubBtn + .headerBtn.headerSubBtn::before{background: #124EA8}
.header.active .headerBtn.lineBtn{border-color: #124EA8}

.btnMenuOpen{width: 21px;height: 18px;position: relative;display: none}
.btnMenuOpen span{display: block;width: 100%;height: 2px;background: #fff;border-radius: 10px;position: absolute;transition: transform 0.2s, opacity 0.2s}
.btnMenuOpen span:nth-child(1){top: 0;left: 0}
.btnMenuOpen span:nth-child(2){top: calc(50% - 1px);left: 0}
.btnMenuOpen span:nth-child(3){bottom: 1px;left: 0}
.btnMenuOpen.active span:nth-child(1){transform: translateY(8px) rotate(45deg)}
.btnMenuOpen.active span:nth-child(2){opacity: 0}
.btnMenuOpen.active span:nth-child(3){transform: translateY(-7px) rotate(-45deg)}
.header.active .btnMenuOpen span{background: #124EA8}

.sideMenu{position: fixed;top: 4.2em;height: calc(100% - 4.2em);width: 100%;overflow-y: auto;right: -101%;transition: right 0.2s;background: #fff}
.sideMenu.active{right: 0}
.headerBtn.sideMenuBtn{color: #404244 !important;font-size: 16px;display: block;width: 100%;text-align: center;padding: 14px 20px}
.headerBtn.sideMenuBtn.active{color: #124EA8 !important}
.headerBtn.sideMenuBtn + .headerBtn.sideMenuBtn{margin: 0}

/*footer*/
.footer{border-top: 1px solid #D5DCE3;padding: 3.13em 20px}
.footer_section{max-width: 1760px;margin: 0 auto}
.footer_header{display: flex;align-items: center;justify-content: space-between;gap: 30px}
.footer_header>img{width: 10.63em}
.footer_linkBox{display: flex;align-items: center;justify-content: flex-end;gap: 2.5em}
.footerLink{font-weight: 700;color: #909AA4;font-size: 0.937em}
.footerInfoBox{display: flex;align-items: center;gap: 1.88em}
.footerInfoBox + .footerInfoBox{margin: 10px 0 0}
.footer_header + .footerInfoBox{margin: 2.5em 0 0}
.footerInfoItem{display: flex;align-items: center;gap: 1em;position: relative}
.footerInfoItem + .footerInfoItem::before{content: "";position: absolute;top: 50%;left: -0.94em;width: 1px;height: 15px;background: #BDC6CF;transform: translateY(-50%)}
.footerInfoItem_name{font-weight: 700;color: #5F656B;font-size:0.875em}
.footerInfoItem_text{color: #5F656B;font-size:0.875em}
.footer_caption{font-size: 0.937em;color: #5F656B;font-weight: 500;margin: 2.22em 0 0}

/*editerBox*/
.editerBox *{font-weight: revert;color: revert;font-family: revert;font-size: revert;line-height: revert;white-space: pre-wrap}
.editerBox img{max-width: 100%}

/*bottomErrMsg*/
.bottomErrMsg{position: fixed;bottom: 100px;transform: translateX(-50%);max-width: 400px;text-align: center;padding: 9px 18px;color: var(--mainTextColor);font-size: 13px;font-weight: 500;opacity: 0;background: rgba(58, 58, 58, 0.8);border-radius: 8px;left: 50%;width: calc(100% - 40px);display: none;transition: opacity 0.2s;z-index: 9999}
.bottomErrMsg.active{opacity: 1}

/*loarding*/
.loadingBox{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9999;display: none}
.loadingBox.active .popup_cover{transition: opacity 0s;opacity: 1}
.loading{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%)}

/*err*/
.pageErrBox{text-align: center;padding: 70px 20px}
.pageErrTitle{font-size: 22px;font-weight: 700}
.pageErrText{margin: 14px 0 0;font-size: 14px}

/*page*/
.pageTitleBox{width: 100%;padding: 20px 20px 30px}
.pageTitle{color: var(--mainTextColor);font-size: 20px;font-weight: 600}
.pageSubTitle{font-weight: 500;margin: 4px 0 0;color: var(--mainTextColor);font-size: 13px}
.pageSubTitleBtn{color: var(--mainTextColor);font-size: 13px;font-weight: 500;margin: 14px 0 0;text-decoration: underline}

/*contentsSection*/
.contentsSection.headerContents{padding-top: calc(4.2em + 2.19em)}

.itemSection{padding: 20px}
.itemSection + .itemSection{border-top: 6px solid #303036}
.itemSection.notPaddingTop{padding-top: 0}
.itemSection.notPaddingSide{padding-left: 0;padding-right: 0}
.itemSection + .itemSection.minLine{border-width: 1px}

/*tap*/
.tapBox{height: 100%;display: flex;align-items: center;gap: 14px;overflow-x: auto;border-bottom: 1px solid #E7ECF2;padding: 0 20px;background: #fff}
.tapItem{height: 100%;white-space: nowrap;font-size: 14px;color: #6E6E77;font-weight: 500;border-bottom: 2px solid transparent;padding: 12px 0}
.tapItem.active{font-weight: 700;border-color: #124EA8;color: #124EA8}
.tapBox.heightSet{height: 44px}

.tapBox.headerComTap{width: 100%;position: absolute;top: 54px;left: 0;background: #19191B;height: 44px;gap: 0;overflow-x: visible;border-bottom: 1px solid #303036}
.tapBox.headerComTap .tapItem{padding: 0 10px;width:100%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(2),.tapBox.headerComTap .tapItem:first-child:nth-last-child(2) ~ .tapItem{width: 50%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(3),.tapBox.headerComTap .tapItem:first-child:nth-last-child(3) ~ .tapItem{width: 33.33%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(4),.tapBox.headerComTap .tapItem:first-child:nth-last-child(4) ~ .tapItem{width: 25%}

.subTapBox{display: flex;align-items: center;gap: 4px;overflow-x: auto;padding: 15px 20px}
.subTapItem{border-radius: 100px;color: #6E6E77;font-size: 13px;padding: 7px 16px;white-space: nowrap}
.subTapItem.active{background: #303036;font-weight: 600;color: var(--mainTextColor)}

.popupTapBox{width: calc(100% - 30px);display: flex;align-items: center;justify-content: flex-start;gap: 14px}
.popupTapItem{font-size: 16px;font-weight: 700;color: #6E6E77}
.popupTapItem.active{color: var(--mainTextColor)}

/*notItem*/
.notItemArea{display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 8px;text-align: center;height: 100%;width: 100%}
.notItemArea.min{height:auto;padding: 30px 0}
.notItemArea.fullPage{min-height: 60vh}
.notItemText{font-size: 13px;font-weight: 500;color: var(--disabledColor)}

/*subLine*/
.subLine{display: block;height: 6px;background: #F3F3F3;width:100%}
.subLine.pageFull{width: 100vw;transform: translateX(-20px)}

/*category*/
.categoryBox{width: 100vw;margin: 15px 0 15px -20px;padding: 0 20px;display: flex;overflow-x: auto}
.categoryItem{padding: 3px 8px;white-space: nowrap;display: inline-block;color: #ACACAC;font-size: 13px;font-weight: 700;border-radius: 43px}
.categoryItem.active{background: #FF7AB7;color: var(--mainTextColor);font-weight: 800}

/*accordion*/
.accordionTitleBox{width: calc(100% - 40px);margin: 0 auto;border-bottom: 1px solid #303036;align-items: center;padding: 20px 0;display: flex}
.accordionBox.active .accordionTitleBox{border: 0}
.accordionIcon{color: #6E6E77;font-size: 14px;font-weight: 700;min-width: 14px;text-align: left;line-height: 1.8}
.accordionBox.active .questionIcon{color: var(--mainTextColor)}
.accordionTextBox{display: flex;align-items: flex-start;width: calc(100% - 5px - 11px);padding-right: 16px}
.accordionTitle{text-align: left;width: 100%;font-size: 14px;font-weight: 600;line-height: 1.8}
.accordionTextBox + img{min-width: 11px;transition: transform 0.2s,opacity 0.2s;opacity: 0.5}
.accordionBox.active .accordionTextBox + img{transform: rotate(180deg);opacity: 1}
.answerText{background: #222225;padding: 20px 20px 30px}
.accordionText{font-size: 13px;line-height: 20px;color: rgba(255, 255, 255, 0.9)}
.answerText .accordionTextBox{padding: 0;width: 100%}

/*input*/
.input_section{padding: 0 20px 50px;width: 100%}

.inputItemBox{margin: 20px 0 0;position: relative}
.inputItemBox:first-child{margin-top: 0}
.inputItemBox.marginUp{margin: 30px 0 0}
.inputName{animation: inputOut 0.1s forwards;position: absolute;z-index: 1;left: 12px;top: 50%;transform: translate(0,calc(-50% - 1px));font-size: 14px;color: #909AA4;opacity: 0}
.inputItemBox.active .inputName{animation: inputIn 0.1s forwards}
.inputItem{box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);border: 1px solid transparent;border-radius: 10px;width: 100%;padding: 13px 12px;font-size: 14px;color: var(--mainTextColor)}
.inputItemBox.active .inputItem{padding: 19px 12px 7px}
.inputItemBox.active .notName .inputItem{padding: 13px 15px}
.err .inputItem{animation: err 0.3s;border-color: #F44D4D}
.selItem .inputItem{color: #1E1E1E;background: url(/assets/images/basic/sel_icon.svg) no-repeat center right 0;background-color: var(--mainTextColor);padding-right: 40px}
.selItem .inputItem:disabled{color: #838383;background-color: rgba(0,0,0,0.1)}
.inputSet.pwBox .inputItem{padding-right: 50px}
.btn_pwCh{position: absolute;right: 8px;top: 50%;transform: translateY(-50%)}
.inputComBox{position: relative}
.inputComBox .inputItem{padding-right: 90px}
.btn_inputSubBtn{min-width: 67px;min-height: 26px;background: #c7c7c7;border-radius: 49px;color: var(--mainTextColor);font-size: 13px;padding: 4px;font-weight: 700;position: absolute;right: 13px;top: 50%;transform: translateY(-50%)}
.btn_inputSubBtn.pBg{background: #FF7AB7}
.cetifiedTimer{font-size: 13px;font-weight: 500;color: var(--mainTextColor);position: absolute;right: 15px;top: 50%;transform: translateY(-50%)}
.cetifiedInput .inputItem{padding-right: 122px}
.errMsg{color: #F04C41;margin: 4px 0 0;font-size: 12px;font-weight: 500}
.inputMsgBox .errMsg{margin: 0}
.inputMsgBox{margin: 4px 0 0;display: flex;gap: 10px;justify-content: space-between}
.inputMsgBox.contentEnd{justify-content: flex-end}
.btn_inputSubItem{font-size: 12px;font-weight: 500;color: var(--mainTextColor);text-decoration: underline;text-align: right}
.inputCaption{margin: 4px 0 0;font-size: 12px;color: rgba(255, 255, 255, 0.6);font-weight: 500}
.inputSet{position: relative}
.notReadOnlyColor .inputItem{color: #1e1e1e;background: #fff}

.inputUnitComBox .inputItem{padding-right: 85px}
.inputUnit{font-size: 15px;font-weight: 700;color: #5D5D5D;position: absolute;top: 50%;transform: translateY(-50%);right: 15px}

.inputBox.selectIn .inputItem{padding-left: 115px}
.inputBox.selectIn .inputItem + .inputItemBox{width: 90px;margin: 0;position: absolute;top: 50%;left: 15px;transform: translateY(-50%)}
.inputItemBox.active .inputBox.selectIn .inputItem + .inputItemBox{transform: translateY(calc(-50% + 7px))}
.inputBox.selectInItem .inputItem{font-size: 11px;padding: 2px 6px !important;border-radius: 5px;background-color: #6E6E77;border-color: #6E6E77;color: var(--mainTextColor);display: block}
.inputBox.selectInItem .selItem .inputItem{background-size: 20px;background-position: center right 5px}
.inputBox.selectInItem .selItem .inputItem::placeholder{color:#fff}

.chk_item{padding: 2px 0;display: flex;align-items: center;justify-content: space-between;gap: 15px}
.chk_item:not(.allChkItem) + .chk_item{margin: 16px 0 0}
.chk_item.allChkItem{margin: 0 0 20px}
.chk_item.allChkItem .chk_item_text{font-weight: 600;font-size: 15px}
.chk_item label{position: relative;padding: 0 0 0 30px;width: calc(100% - 34px);font-weight: 700}
.chk_item label::before{content: "";position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 20px;height: 20px;background: url(/assets/images/basic/chk_off.svg) no-repeat center;background-size: 100%}    
.chk_item input:checked + label::before{background-image: url(/assets/images/basic/chk_on.svg)}
.chk_item_text{color: var(--mainTextColor);font-size: 13px}
.chkBoxBtn{color:#6E6E77;text-decoration: underline;font-size: 13px;text-align: right;white-space: nowrap}
.inputItemBox + .chk_item{margin-top: 16px}

.textareaItem{display: block;resize: none;min-height: 180px;border: 1px solid transparent;border-radius: 10px;width: 100%;padding: 12px 15px;font-size: 14px;color: var(--mainTextColor)}
.textareaCount{font-size: 13px;width: 100%;text-align: right;padding: 8px 0 0;color: #ACACAC}
.textAreaBtnComBox{position: relative}
.textAreaBtnComBox .textareaItem{background: #fff;color: #1E1E1E;padding-right: 90px}
.textAreaBtnComBox .btn_inputSubBtn{top: 14px;transform: translateY(0)}

.inputFileList{width: 100vw;transform: translateX(-20px);padding: 0 20px;overflow-x: auto;display: flex;gap: 12px}
.inputFileBox .inputFileList label{min-width: 70px;width: 70px;height: 70px;padding: 0;border-radius: 14px;display: flex;align-items: center;justify-content: center;background: #303036}
.fileItemMultipleItem{background-repeat: no-repeat;background-size: cover;background-position: center;width: 70px;height: 70px;min-width: 70px;border-radius: 14px;position: relative}
.fileItemMultipleItemDel{position: absolute;top:5px;right:5px;z-index: 1}

.react-datepicker-wrapper{width: 100%}
.datePicerInputBox .inputItem{width: 100%;background: url(/assets/images/basic/cal.svg) no-repeat right 15px center;background-color: var(--mainTextColor);color: #1E1E1E}

.inputSubCaptionItem{position: relative}
.inputSubCaptionItem .inputItem{padding-left: 33px}
.inputSubCaption_text{font-size: 15px;position: absolute;left: 15px;top: 50%;transform: translateY(-50%)}

.customSelectInputBox{position: relative}
.customSelectInputBox.active::after{transform: translateY(-50%) rotate(180deg)}
.customSelectInputBox::after{content: "";width:11px;height:6px;position: absolute;background: url(/assets/images/basic/sel.svg) no-repeat center;top: 50%;transform: translateY(-50%);right: 12px}
.customSelectInputBox .inputItem{background: #303036;color: var(--mainTextColor);padding-right: 34px}
.customSelect_optionBox{border: 1px solid #424243;border-radius: 10px;margin: 5px 0 0;background: #303036;overflow: hidden;display: none;max-height: 200px;overflow-y: auto}
.customSelect_optionBox.active{display: block}
.customSelect_option{font-size:13px;display: block;color: rgba(255, 255, 255, 0.9);width: 100%;text-align: left;padding: 10px}
.customSelect_option.active{font-weight: 600}

.inputSubBox{display: flex;gap: 15px;align-items: flex-start;justify-content: space-between}
.inputSubBox .inputItemBox{width: calc(50% - 7.5px)}

.inputSubBoxOnly{margin: 24px 0 0}
.inputName.pageName{animation: none;transform: translate(0,0);position: static;color: var(--mainTextColor);font-size: 14px;font-weight: 700;opacity: 1}
.inputName.pageName + .inputItemBox{margin-top: 8px}

.inputViewBox{margin: 8px 0 0}
.inputViewText{border: 1px solid transparent;border-radius: 8px;width: 100%;padding: 13px 15px;font-size: 14px;color: var(--mainTextColor);background: #303036}
.inputViewBox.textAreaViewBox .inputViewText{display: block;resize: none;min-height: 180px;border-radius: 10px;padding: 12px 15px}
.fileViewBox{margin: 8px 0 0;width: 100vw;transform: translateX(-20px);padding: 0 20px;overflow-x: auto;display: flex;gap: 12px}
.fileViewBox>div{width: 70px;height: 70px;min-width: 70px;border-radius: 14px;position: relative}

/*fixedBox*/
.fixedPoint{position: relative}
.hdFtFixedItem{position: absolute;bottom: 0}
.headerFixedItem{position: fixed;top: 54.59px;z-index: 1000;width:100%}

/*page*/
.logoSection{padding: calc(3.44em + 2.19em + 6.25em) 20px 35.5em;background: url(/assets/images/bg_1.png) no-repeat center bottom;background-size: cover;position: relative}
.logoSection.notBg{background: transparent;padding: calc(3.44em + 2.19em + 6.25em) 20px 11.25em}
.logoSection.notImg{padding-bottom: 10.63em}
.logoSection.wType{background-image: url(/assets/images/bg.png)}
.logoSection>img{max-width: 31.06em;width: 100%;margin: 0 auto}
.logoSection_text{text-align: center;font-size: 1.63em;font-weight: 500;margin: 1.92em 0 0;color: #4D6B98}
.logoSection .btn_box{margin: 3.13em auto 0;max-width: 375px;display: flex;align-items: center;justify-content: space-between;gap: 20px}
.logoSectionBtn{width: 38%;border-radius: 8px;background: #124EA8;font-size: 1.125em;color: #fff;display: flex;align-items: center;justify-content: center;gap: 1em;border-color: #124EA8}
.logoSectionBtn>img{min-width: 1em}
.logoSectionBtn.line{background: #fff;color: #124EA8}
.logoSectionBtn.logoSectionBtn_big{width: calc(62% - 20px)}
.logoSection_title{font-size: 3.13em;text-align: center;font-weight: 700;color: #0C2B48}

.logoSection.wType .logoSection_text{color:#fff}
.logoSection.wType .logoSectionBtn{color: #124EA8;background: #fff;border-color: #fff}
.logoSection.wType .logoSectionBtn.line{background: #124EA8;color: #fff;border-color: #fff}

.main_topImgBox{position: absolute;bottom: -16.88em;left: 50%;transform: translateX(-50%);max-width: 1080px;width: 100%}
.main_topImgBox>img{width:100%}

.logoSection + .mainSection{padding: 25em 20px 7.5em}
.mainSection{padding: 7.5em 20px 7.5em}
.mainSection + .mainSection{padding-top: 0}
.mainSectionTitleBox{text-align: center}
.mainSectionTitle{font-size: 2.5em;font-weight: 700}
.mainSectionText{font-size: 1.38em;margin: 1em 0 0}
.mainSectionCaptionBox{display: flex;align-items: center;justify-content: center;margin: 1.5em 0 0;flex-wrap: wrap;gap: 10px}
.mainSectionCaption{border-radius: 200px;background: rgba(0, 148, 255, 0.1);font-size: 1.125em;font-weight: 600;color: #127AE4;padding: 5px 15px}
.mainAboutItem_area{display: flex;align-items: center;justify-content: center;gap: 3.13em;max-width: 1140px;margin: 5em auto 0}
.mainAboutItem{width: calc(33.33% - 2.08em);text-align: center}
.mainAboutItem_title{color: #124EA8;font-size: 1.25em;font-weight: 700}
.mainAboutItem_text{color: #5F656B;line-height: 160%;margin: 1.125em 0 0}
.mainAboutItem_img{width: 5em;height: 5em;border-radius: 16px;background: #F5F7FB;display: flex;align-items: center;justify-content: center;margin: 0 auto 2em}
.mainAboutItem_img img{width: 71%}

.mainAboutItem_slideArea{width: 100vw;margin: 80px 0 0 -20px;display: none}
.mainAboutItem_slideArea .swiper{overflow: visible}
.mainAboutItem_slideArea .swiper-wrapper{margin: 0 auto}
.mainAboutItem_slideArea .mainAboutItem{width: 100%}

.swiper-pagination-progressbar{margin: 40px auto 0;height: 3px;background: #E7ECF2;border-radius: 13px;max-width: calc(100% - 40px)}
.swiper-pagination-progressbar-fill{height: 100%;display: block;background: rgb(18, 78, 168);border-radius: 3px;transform-origin: left center}

.mainPartnerItemBox{max-width: 1380px;margin: 3.75em auto 0;display: flex;flex-wrap: wrap;gap: 10px 0}
.mainPartnerItem{background-repeat: no-repeat;background-position: center;background-size: contain;width: 16.66%;padding: 0 0 2.8%}
.mainPartner_slideArea{display: none}
.mainPartner_slideArea{width: 100vw;margin: 30px 0 0 -20px;display: none}
.mainPartner_slideArea .swiper{overflow: visible}
.mainPartner_slideArea .swiper-wrapper{margin: 0 auto}
.mainPartner_slideArea .mainPartnerItem{width: 100%;padding: 0 0 16%}

.mainSection.fullPage{padding-left: 0;padding-right: 0}
.mainAppAbout{display: flex;gap: 5em;align-items: center;justify-content: flex-end}
.mainAppAbout_textBox{width: 35em;padding-left: 20px}
.mainAppAbout_subTitle{color: #36B37E;font-weight: 700;font-size: 0.875em}
.mainAppAbout_title{font-size: 1.88em;font-weight: 700;margin: 0.3em 0 0}
.mainAppAbout_text{color: #5A7184;font-size: 1em;margin: 2em 0 0}
.mainAppAbout_InfoBox{display: flex;align-items: flex-start;justify-content: flex-start;gap: 1.88em;margin: 2em 0 0}
.mainAppAboutTextItem>img{width: 2.81em}
.mainAppAboutTextItem{width: calc(50% - 0.94em)}
.mainAppAboutTextItem_title{font-size: 1.125em;font-weight: 700;margin: 1em 0 0}
.mainAppAboutTextItem_text{color: #5A7184;margin: 4px 0 0}
.appBtnBox{display: flex;gap: 1em;margin: 6em 0 0}
.appBtn{width: 10.81em}
.appBtn img{width: 100%}
.mainAppAbout_imgBox{width: 51.75em;background: url(/aseets/images/main_img_1_bg.png) no-repeat center right;background-size: contain}
.mainAppAbout_imgBox>img{width: 100%}

.funcPage{max-width: 1720px;margin: 0 auto;padding: 0 20px 9.38em}
.funcSection{padding: 6.25em;border-radius: 40px;background: #F7F9FC}
.funcSection + .funcSection{margin: 3.13em 0 0}
.funcSection_name{border-radius: 38px;background: #DBF5EA;display: inline-block;text-align: center;color: #0D7E4E;font-size: 1.25em;font-weight: 600;padding: 6px 15px;margin: 0 0 2em}
.funcArea{display: flex;align-items: center;justify-content: space-between;gap: 3.13em}
.funcArea + .funcArea{margin: 6.25em 0 0}
.funcArea.reverse{flex-direction: row-reverse}
.funcArea_textBox{width: calc(100% - 3.13em - 37.5em)}
.funcArea.reverse .funcArea_textBox{text-align: right}
.funcArea_title{font-size: 1.88em;font-weight: 700}
.funcArea_text{font-size: 1.125em;line-height: 170%;margin: 1.78em 0 0}
.funcArea_imgBox{min-width: 37.5em;width: 37.5em}
.funcArea_imgBox img{width: 100%}
.funcArea_subList{display: flex;align-items: center;gap: 10px}
.funcArea_subList + .funcArea_subList{margin: 1.25em 0 0}
.funcArea_text + .funcArea_subList,.funcArea_title + .funcArea_subList{margin: 1.88em 0 0}
.funcArea_subList img{min-width: 1.75em;width: 1.75em}
.funcArea_subList_text{font-size: 1.125em;font-weight: 500}
.tapFixedBox{margin: 0 0 20px;display: none;min-height: 46.59px}

.priceDataArea{max-width: 1040px;margin: 5em auto 0;display: flex;gap: 2.5em}
.priceArea{width: calc(50% - 1.25em);border-top: 4px solid #0094FF;border-radius: 8px;padding: 1.88em;background: #fff}
.priceArea.bColor{border-top: 4px solid #124EA8}
.priceData_name{font-size: 1.3em;font-weight: 700;color: #0094FF}
.priceArea.bColor .priceData_name{color: #09519A}
.priceData_subName{color: #5A7184;font-size: 0.937em}
.priceData_priceBox{display: flex;align-items: center;justify-content: space-between;gap: 1.25em;margin: 15px 0 0}
.priceData_priceCaption{font-size: 1.125em;font-weight: 500;color: #5A7184}
.priceData_price{font-size: 1.125em;font-weight: 500;color: #5A7184}
.priceData_price span{color: #0094FF;font-size: 2em;font-weight: 700;margin: 0 4px 0 0}
.priceArea.bColor .priceData_price span{color: #09519A}
.priceSubDataList{border-top: 1px solid #E5EAF4;margin: 1.25em 0 0;padding: 1.25em 0 0}
.priceSubDataItem{display: flex;align-items: center;gap: 10px}
.priceSubDataItem.priceSubData_func{background: rgba(245, 247, 251, 0.5);border-radius: 12px;justify-content: center;padding: 1.25em 10px}
.priceSubDataItem + .priceSubDataItem{margin: 1.25em 0 0}
.priceSubDataItem img{width: 1.5em;min-width: 1.5em}
.priceSubDataItem_text{font-size: 1.1em}
.priceCaption{font-size: 1.125em;color: #5F656B;text-align: center;margin: 2.78em 0 0}
.priceDataAreaSlide{display: none;margin: 60px auto 0}

.priceDataAreaSlide .swiper-pagination-progressbar{max-width: 100%;margin: 25px auto 0}
.funcPage_title{font-size: 2.5em;font-weight: 700;text-align: center;margin: 3em auto 2em;color: #183B56}
.pricePage{display: flex;justify-content: space-between;gap: 1.88em;align-items: center;max-width: 1720px;margin: 0 auto;padding: 0 20px 9.38em}
.priceInfoAreaBox{display: flex;flex-direction: column;gap: 1.88em;width: calc(50% - 0.94em)}
.priceInfoArea{background: #F5F7FB;border-radius: 1.88em;padding: 3.13em;width: 100%}
.priceInfoBox{display: flex;align-items: center;gap: 1.25em}
.priceInfoImgBox{width: 5em;height: 5em;min-width: 5em;background: #fff;border-radius: 1em;display: flex;align-items: center;justify-content: center;box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1)}
.priceInfoImgBox img{width: 75%}
.priceInfoBox_title{color: #124EA8;font-weight: 600;font-size: 1.5em}
.priceInfoBox_text{color: #404244;font-size: 1.125em;margin: 2px 0 0}
.priceInfo_list{margin: 1.88em 0 0}
.priceInfo_listItem + .priceInfo_listItem{margin: 1em 0 0}
.priceInfo_listItem_title{font-size: 1.125em;font-weight: 600}
.priceInfo_listItem_text{margin: 5px 0 0}

/*반응형*/
@media (max-width:1800px){

}
@media (max-width:1680px){
	
}
@media (max-width:1600px){
    html,body{font-size: 15px}
}
@media (max-width:1440px){
    html,body{font-size: 14px}

    /*page*/
    .main_topImgBox{max-width: 970px}
}
@media (max-width:1366px){
    html,body{font-size: 13px}

    /*page*/
    .main_topImgBox{max-width: 910px}
}
@media (max-width:1280px){
    html,body{font-size: 12px}

    /*page*/
    .main_topImgBox{max-width: 850px}
}
@media (max-width:1152px){
    html,body{font-size: 11px}

    /*page*/
    .main_topImgBox{max-width: 780px}
}
@media (max-width:1024px){
    html,body{font-size: 13px}

    /*page*/
    .main_topImgBox{max-width: 930px}
    .mainAppAbout_imgBox{width: calc(100% - 35em - 5em)}
    .funcArea_title{font-size: 20px}
    .funcSection{padding: 40px;border-radius: 30px}
}
@media (max-width:960px){
    /*headear*/
    .headerMenuBox{display: none}
    .btnMenuOpen{display: block}
    .headearSubSection{display: none}
    .btnHome{width:100px}

    .contentsSection.headerContents{padding-top: 3.44em}
    .mItem{display: block}

    /*footer*/
    .footer{padding: 30px 20px 85px}
    .footer_header{flex-direction: column;align-items: flex-start;gap: 20px}
    .footer_header>img{width: 120px}
    .footer_linkBox{gap: 20px}
    .footerInfoBox {display: block}
    .footerInfoItem + .footerInfoItem{margin: 10px 0 0}
    .footerInfoItem + .footerInfoItem::before{display: none}
    .footer_caption{font-size: 13px}

    /*page*/
    .logoSection{padding: 80px 20px 30px}
    .logoSection.notBg{padding: 80px 20px}
    .logoSection>img{max-width: 180px}
    .logoSection_text{font-size: 16px;margin: 20px 0 0}
    .logoSection .btn_box{margin: 40px auto 0;max-width: 300px}
    .logoSectionBtn{height: 42px;font-size: 13px;gap: 10px}
    .logoSectionBtn>img{min-width: 14px;width: 14px}
    .main_topImgBox{position: static;transform: translate(0, 0);top: initial;left: initial;margin: 60px auto 0}

    .mainAboutItem_slideArea{display: block}
    .mainAboutItem_area{display: none}
    .mainSectionCaption{padding: 5px 12px}
    .mainSectionTitle{font-size: 24px}
    .mainSectionText{font-size: 14px}
    .mainSectionCaption{padding: 5px 12px}

    .logoSection + .mainSection{padding: 90px 20px}
    .mainSection{padding: 90px 20px}
    .mainSection + .mainSection{padding-top: 0}

    .mainPartnerItemBox{display: none}
    .mainPartner_slideArea{display: block}

    .mainAppAbout{flex-direction: column;gap: 30px}
    .mainAppAbout_textBox{padding: 0 20px;width: 100%}
    .mainAppAbout_subTitle{text-align: center}
    .mainAppAbout_title{text-align: center}
    .mainAppAbout_text{text-align: center;margin: 1.5em 0 0}
    .appBtnBox{margin: 3em 0 0;justify-content: center}
    .mainAppAbout_imgBox{width: 100%}

    .funcSection{padding: 20px;border-radius: 20px}
    .funcArea,.funcArea.reverse{flex-direction: column-reverse;gap: 16px}
    .funcArea_title{font-size: 18px}
    .funcArea_textBox{width: 100%}
    .funcArea_text{font-size: 14px;margin: 16px 0 0}
    .funcArea_imgBox{max-width: 100%;width: 100%;min-width: 0}
    .funcArea + .funcArea{margin: 40px 0 0}
    .funcSection + .funcSection{margin: 40px 0 0}

    .funcArea_text + .funcArea_subList, .funcArea_title + .funcArea_subList{margin: 20px 0 0}
    .funcArea_subList + .funcArea_subList{margin: 14px 0 0}
    .funcArea_subList img{width: 20px;min-width: 20px}
    .funcArea_subList_text{font-size: 14px}
    .funcSection_name{margin: 0 0 24px}

    .tapFixedBox{display: block}

    .logoSection_title{font-size: 25px}

    .priceDataArea{display: none}
    .priceDataAreaSlide{display: block}
    .priceCaption{margin: 30px auto 0}
    .priceArea{min-height: 480px;width:100%}

    .logoSection.notImg{padding-bottom: 95px}

    .funcPage_title{font-size: 20px;margin: 50px auto 30px}

    .pricePage{flex-direction: column;justify-content: center;gap: 20px}
    .priceInfoAreaBox{gap: 20px;width: 100%}
    .priceInfoArea{padding: 30px;border-radius: 30px}
    .priceInfoBox{flex-direction: column;align-items: flex-start;gap: 16px}
    .priceInfoImgBox{width: 60px;height: 60px;min-width: 60px}
    .priceInfo_list{margin: 20px 0 0}
    .priceInfo_listItem{margin: 10px 0 0}
}
@media (max-width: 720px){
    
}
@media (max-width: 650px){
    
}
@media (max-width: 550px){
}
@media (max-width: 490px){
    /*page*/
    .mainAppAbout_InfoBox{flex-direction: column}
    .mainAppAboutTextItem{width: 100%}
}
@media (max-width: 400px){
}
@media (max-width: 340px){
}
/*//*/